<template>
  <main class="mainlayout">
 
    <Sidebar/> 

    <div class="content">   
      <!-- <div class="newdevice"> 
        <button class="insead autowidth large left" :disabled="loading" :class="{disabled: loading}" @click="addDevice">Add new device</button>   
        <input v-model="newDevice.id" type="text" :disabled="loading" autocomplete="off" placeholder="Serial number (Id)" /> 
        <input v-model="newDevice.name" type="text" :disabled="loading" autocomplete="off" placeholder="Name" /> 
        <input v-model="newDevice.mac" type="text" :disabled="loading" autocomplete="off" placeholder="MAC" />                    
      </div>
      <br>  
      <br>   -->
      <button class="insead autowidth large" @click="addRow">Add New Session</button>
      <br>  
      <br>
      <input v-model.trim="searchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <br>
      <br>      
      <VueGoodTable
      ref="datatable"
      :columns="columns"
      :rows="sessions"
      striped
      styleClass="vgt-table striped"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm
      }"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }">
        <template v-slot:selected-row-actions>  
          <button class="insead autowidth right" :disabled="loading || !selectedSessions.length || (!$store.getters.isAdmin && !$store.getters.isOrgAdmin && selectedSessions.filter(s => s.ownerId != $store.getters.userId).length > 0)" :class="{disabled: loading || !selectedSessions.length}" @click="bulkDelete">Bulk delete</button>              
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" class="rowActions">
            <button class="insead autowidth" @click="editAdmins(props.row.id)">Admins</button>
            <button class="insead autowidth right" @click="editUsers(props.row.id)">Users</button>
            <button class="insead autowidth right" @click="editRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">Edit</button>
            <button class="insead autowidth right" @click="copyRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">Copy</button>
            <button class="insead autowidth right" @click="deleteRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">Delete</button>
          </span>
          <span v-else-if="props.column.field == 'status'">
            {{formatStatus(props.row.status)}}
          </span>
          <span v-else-if="props.column.field == 'startDate'">
            {{ new Date(props.row.startDate).toLocaleString() }}
          </span>
          <span v-else-if="props.column.field == 'endDate'">
            {{ new Date(props.row.endDate).toLocaleString() }}
          </span>
          <span v-else-if="props.column.field == 'created'">
            {{ new Date(props.row.created).toLocaleString() }}
          </span>
          <!-- <span v-else-if="props.column.field == 'experience.name'">
            {{ props.row.experience ? props.row.experience.name : 'Custom' }}
          </span> -->
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </VueGoodTable>

      <span class="spinner relative" v-if="loading" />
    </div>

    <Modal v-if="showModal" @close="showModal = false; error = ''; if($route.name != 'Sessions'){ $router.replace({name: 'Sessions'}) }" class="sessionmodal">      
      <h3 slot="header" v-if="tempSession.id">Edit Session #{{tempSession.id}}</h3>
      <h3 slot="header" v-else-if="tempSession.copy">Copy session (based on #{{tempSession.copy}})</h3>
      <h3 slot="header" v-else>Add new session</h3>
      <div slot="body" style="max-height: calc(100vh - 280px);">
        <div class="basic">
          <!-- <input v-model="newSession.Id" type="text" placeholder="session Id (only when updating a session, it is generated otherwise)">  -->
          <label>Name</label>
          <input v-model.trim="tempSession.name" :disabled="loading" type="text" placeholder="session name"> 

          <div v-if="$store.getters.isAdmin">
            <label>Owner {{tempSession.id ? '' : '(optional)'}}</label>
            <!-- <input v-model.trim="tempSession.ownerId" type="text" placeholder="user Id of professor (optional, GUID format)"> -->          
            <vSelect :disabled="loading"  
              :options="assignableSessionOwnerUsers" 
              :filter="sessionOwnerSearch" 
              v-model="tempSession.ownerId" 
              :reduce="user => user.id" 
              label="email">
              <template v-slot:option="user">              
                {{ user.email }} - {{user.firstName}} {{user.lastName}}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"></span>
              </template> 
              <template #selected-option="{ email, firstName, lastName }">
                {{ email }} - {{firstName}} {{lastName}}
              </template>           
            </vSelect>
          </div>

          <div class="form-left">
            <v-date-picker v-model="tempSession.startDate" mode="dateTime" is24hr>    
              <template v-slot="{ inputValue, inputEvents }">
                <label>Start date</label>  
                <input    
                  :disabled="loading"  
                  type="text"      
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <div class="form-right">
            <v-date-picker v-model="tempSession.endDate" mode="dateTime" is24hr>     
              <template v-slot="{ inputValue, inputEvents }">
                <label>End date</label>  
                <input      
                  :disabled="loading"  
                  type="text"      
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>          
                    
          <label v-if="$store.getters.isAdmin">Organization</label>
          <select v-if="$store.getters.isAdmin" class="select-css" v-model="tempSession.organizationId">
            <option :value=null selected>None</option>
            <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
              {{org.name}}
            </option>
          </select>

          <label v-show="!tempSession.custom">Experience (Legacy)</label>
          <select v-show="!tempSession.custom" class="select-css" v-model="tempSession.experienceId">
            <option v-bind:value=null style="color: green;">None &#9888;</option>
            <option v-for="exp in legacyExperiences" v-bind:value="exp.id" :key="exp.id">
              {{exp.name}} 
            </option>
          </select>
                    
          <div style="clear:both;">
            <label for="customcheckbox" style="cursor: pointer;">Custom Structure (legacy, only for backward compatibility)</label>
            <input id="customcheckbox" :disabled="loading" type="checkbox" style="margin-top: 16px; margin-left: 10px; cursor: pointer;" v-model="tempSession.custom">
            <textarea class="jsonarea" v-show="tempSession.custom" v-model="tempSession.structure" :disabled="!tempSession.custom" placeholder="json structure"/>             
            <div v-if="testSessionJsonInvalid" style="color: red;">Invalid JSON custom structure</div> 
          </div>
        </div>

        <div class="editor" v-show="!tempSession.custom">          
          <div class="masters" v-if="!tempSession.experienceId">
            <label style="white-space: nowrap; padding-left: 25px;">Available content</label>
            <div style="clear:both; max-height: calc(100% - 80px); overflow-y: auto;">
            <ul style="clear:both;" 
              class="tree" 
              v-for="exp in experiences" :key="'tree' + exp.id">
              <TreeItem  
                class="treeitem"
                :editorMode="true"
                @select="treeItemSelected"
                :item="exp"    
                :selectedItem="selectedTreeItem"        
                :level="0"
              >
              </TreeItem>   
            </ul>
            </div>
            <button @click="addContentBlock" :disabled="loading" class="insead white">Add</button>
          </div>
          <div class="details" v-if="!tempSession.experienceId">
            <label style="white-space: nowrap;  padding-left: 25px;">Session content</label>
            <div style="clear:both; max-height: calc(100% - 80px); overflow-y: auto;">              
              <ul style="clear:both;"
                class="tree"
                v-for="block in tempSession.contentBlocks" :key="'block' + block.id"> 
                <!-- <li  
                  @click="selectedContentBlock = block"
                  :class="{selected: selectedContentBlock == block}"
                >
                  {{block.id}}
                </li> -->
                <TreeItem  
                  class="treeitem"
                  :editorMode="true"
                  :publishDate="tempSession.published ? tempSession.published : tempSession.created"
                  @select="contentBlockSelected"
                  :item="block"    
                  :selectedItem="selectedContentBlock"        
                  :level="0"
                >
                </TreeItem>              
              </ul>
            </div>
            <button @click="removeContentBlock" :disabled="loading" style="width: 40%;" class="insead white">Remove</button>
            <button @click="structureToClipboard" :disabled="loading" style="width: 50%; right: 0;" class="insead white">Copy JSON</button>
          </div>
        </div>    
      </div>
      <div slot="body" v-if="tempSession.copy" style="padding-top:10px;"><input type="checkbox" v-model="tempSession.copyAreas">Also copy any custom areas defined for video heatmaps</div>
      <div slot="footer">        
        <div class="formerror" style="clear:both;" v-if="error">{{error}}</div>
        <div v-if="tempSession.status && tempSession.status != 0" style="color: red; padding-bottom: 5px;">WARNING: Modifying a session in active state is not recommended!</div>  
        <button class="insead" :class="{red: tempSession.status && tempSession.status != 0}" v-if="tempSession.id" @click="updateSession" :disabled="loading || testSessionJsonInvalid">Update{{publishContent ? ' (including content)' : ''}}{{tempSession.status != 0 ? ' anyway with risk of breaking' : ''}}</button>                
        <button class="insead" v-else @click="addSession" :disabled="loading || testSessionJsonInvalid">Create</button>  
        <div v-if="/*contentIsNewer && */!tempSession.custom && tempSession.id" style="padding-top: 20px; text-align: right;">   
          <input type="checkbox" style="margin-right: 20px; width: 20px; height: 20px; vertical-align: sub;" v-model="publishContent" /> 
          <span>When updating, also refresh session content (existing blocks to latest version + any currently added/removed blocks)
            <br>
            <i>[last published: {{tempSession.published ? new Date(tempSession.published).toLocaleString() : 'never'}}]</i>
          </span>
        </div>
      </div>
    </Modal>  

    <Modal v-if="showUsersModal" @close="showUsersModal = false; error = ''; $router.replace({name: 'Sessions'})" class="usersmodal">      
      <h3 slot="header">Add/Remove Users for Session #{{tempSession.id}}<span class="spinner" v-if="loading" /></h3>     
      <div slot="body">
        
        <div class="usertabs">
          <div class="usertab">
            <input v-model="userSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search available users..." /> 
            <br>
            <br> 
            <VueGoodTable
              ref="usersdatatable"
              :columns="sessionUserColumns"
              :rows="availableUsers"
              striped
              max-height="500px"
              :fixed-header="true"
              styleClass="vgt-table striped"
              :search-options="{
                enabled: false,
                externalQuery: userSearchTerm
              }"
              :select-options="{ 
                enabled: true,
                selectOnCheckboxOnly: false 
              }">
                <template v-slot:selected-row-actions>  
                  <button class="insead autowidth right" :disabled="loading" :class="{disabled: loading}" @click="addUsers">Add to session</button>                   
                </template>
            </VueGoodTable>
          </div>
          <div class="usertab">
            <input v-model="sessionUserSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search users in session..." /> 
            <br>
            <br> 
            <VueGoodTable
              ref="sessionusersdatatable"
              :columns="sessionUserColumns"
              :rows="tempSession.users"
              striped
              max-height="500px"
              :fixed-header="true"
              styleClass="vgt-table striped"
              :search-options="{
                enabled: false,
                externalQuery: sessionUserSearchTerm
              }"
              :select-options="{ 
                enabled: true,
                selectOnCheckboxOnly: false 
              }">
                <template v-slot:selected-row-actions>  
                  <button class="insead autowidth right" :disabled="loading" :class="{disabled: loading}" @click="removeUsersModal = true">Remove from session</button>                   
                </template>
            </VueGoodTable>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="formerror" v-if="error">{{error}}</div>
      </div>
    </Modal>

    <Modal v-if="removeUsersModal" @close="removeUsersModal = false;" class="removeusersmodal">      
      <h3 slot="header">Remove users</h3>
      <div slot="body">
        Are you sure these users can be safely removed from this session (no live session ongoing)? Current status of this session is: {{formatStatus(tempSession.status)}}
      </div>
      <div slot="footer">                
        <button class="insead white" @click="removeUsersModal = false">Cancel</button> 
        <button class="insead red" @click="removeUsersModal = false; removeUsers()">Confirm removal</button>               
      </div>
    </Modal>   

    <Modal v-if="showAdminsModal" @close="showAdminsModal = false; error = ''; $router.replace({name: 'Sessions'})" class="usersmodal">      
      <h3 slot="header">Add/Remove admins for Session #{{tempSession.id}}<span class="spinner" v-if="loading" /></h3>     
      <div slot="body">
        
        <div class="usertabs">
          <div class="usertab">
            <input v-model="userSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search available users..." /> 
            <br>
            <br> 
            <VueGoodTable
              ref="adminsdatatable"
              :columns="sessionUserColumns"
              :rows="availableAdmins"
              striped
              max-height="500px"
              :fixed-header="true"
              styleClass="vgt-table striped"
              :search-options="{
                enabled: false,
                externalQuery: userSearchTerm
              }"
              :select-options="{ 
                enabled: true,
                selectOnCheckboxOnly: false 
              }">
                <template v-slot:selected-row-actions>  
                  <button class="insead autowidth right" :disabled="loading" :class="{disabled: loading}" @click="addAdmins">Add to session</button>                   
                </template>
            </VueGoodTable>
          </div>
          <div class="usertab">
            <input v-model="sessionUserSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search admins in session..." /> 
            <br>
            <br> 
            <VueGoodTable
              ref="sessionadminsdatatable"
              :columns="sessionUserColumns"
              :rows="tempSession.admins"
              striped
              max-height="500px"
              :fixed-header="true"
              styleClass="vgt-table striped"
              :search-options="{
                enabled: false,
                externalQuery: sessionUserSearchTerm
              }"
              :select-options="{ 
                enabled: true,
                selectOnCheckboxOnly: false 
              }">
                <template v-slot:selected-row-actions>  
                  <button class="insead autowidth right" :disabled="loading" :class="{disabled: loading}" @click="removeAdminsModal = true">Remove from session</button>                   
                </template>
            </VueGoodTable>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="formerror" v-if="error">{{error}}</div>
      </div>
    </Modal> 

    <Modal v-if="removeAdminsModal" @close="removeAdminsModal = false;" class="removeadminsmodal">      
      <h3 slot="header">Remove admins</h3>
      <div slot="body">
        Are you sure these admins can be safely removed from this session (no live session ongoing)? Current status of this session is: {{formatStatus(tempSession.status)}}
      </div>
      <div slot="footer">                
        <button class="insead white" @click="removeAdminsModal = false">Cancel</button> 
        <button class="insead red" @click="removeAdminsModal = false; removeAdmins()">Confirm removal</button>               
      </div>
    </Modal>  

    <Snackbar ref="snackbar" /> 
 
  </main>
</template>

<script>
import axios from 'axios';

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Modal from '@/components/Modal.vue'
import Sidebar from '@/components/Sidebar.vue'
import TreeItem from '@/components/TreeItem.vue'
import vSelect from 'vue-select'
import {Roles} from '@/roles.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Sessions',
  data: function(){
    return {
      sessionUserColumns: [
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Organization',
          field: 'organization.name',
        },
        {
          label: 'First Name',
          field: 'firstName',
        },
        {
          label: 'Last Name',
          field: 'lastName',
        },  
        // {
        //   label: 'ID',
        //   field: 'id',
        // },
      ],
      columns: [
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Organization',
          field: 'organization.name',
          filterOptions: {
            enabled: this.$store.getters.isAdmin, 
            placeholder: 'Filter by Org', 
          },
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Start Date',
          field: 'startDate',          
        },
        {
          label: 'End Date',
          field: 'endDate',
        },
        {
          label: 'Created',
          field: 'created',
        }, 
        // {
        //   label: 'Structure',
        //   field: 'structure',
        // },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            //styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter by Status', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            filterDropdownItems: ['NotStarted','Guided','Paused','SelfPaced','Finished'], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          }
        },
        // {
        //   label: 'Message',
        //   field: 'message',
        // },
        {
          label: 'OwnerId',
          field: 'ownerId',
        },
        // {
        //   label: 'Experience',
        //   field: 'experience.name',
        //   filterOptions: {
        //     //styleClass: 'class1', // class to be added to the parent th element
        //     enabled: true, // enable filter for this column
        //     placeholder: 'Filter by Experience', // placeholder for filter input
        //     // filterValue: 'Jane', // initial populated value for this filter
        //     // filterDropdownItems: experiences.map(e => e.name), // dropdown (with selected values) instead of text input
        //     // filterFn: this.columnFilterFn, //custom filter function that
        //     // trigger: 'enter', //only trigger on enter not on keyup 
        //   },
        // },
        {
          label: 'Actions',
          field: 'actions'
        },
      ],      
      sessions: [],
      experiences: [],
      organizations: [],
      loading: false,
      error: undefined,
      searchTerm: undefined,
      userSearchTerm: undefined,
      sessionUserSearchTerm: undefined,
      showModal: false,  
      showUsersModal: false,  
      removeUsersModal: false,
      showAdminsModal: false,  
      removeAdminsModal: false,
      selectedTreeItem: undefined,
      selectedContentBlock: undefined,
      publishContent: false,
      tempSession: {
        id: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined,
        structure: undefined,
        ownerId: undefined,
        custom: undefined,
        contentBlocks: [],
        organizationId: null
      }, 
      users: []
    }
  },
  computed: {   
    testSessionJsonInvalid(){
      try{
        if(!this.tempSession.experienceId && this.tempSession.custom)
          JSON.parse(this.tempSession.structure)        
        return false
      }
      catch{
        return true
      }
    },
    // contentIsNewer(){
    //   return !this.tempSession.custom && (this.tempSession.contentBlocks.some(b => b.published > this.tempSession.published) || !this.tempSession.published)
    // },
    assignableSessionOwnerUsers(){
      return [{email: "No owner", id: null}].concat(this.users.filter(u => u.roles && (u.roles.includes(Roles.Professor) || u.roles.includes(Roles.Admin) || u.roles.includes(Roles.OrgAdmin))))
    },
    selectedSessions(){
      return this.$refs.datatable.selectedRows;
    },
    availableUsers(){
      let tmp = this.tempSession.users.map(u => u.id)
      //console.log(tmp)
      return (tmp ? this.users.filter(user => user.id && !tmp.includes(user.id)) : this.users)?.filter(u => this.$store.getters.isAdmin || ((this.$store.getters.isProfessor || this.$store.getters.isOrgAdmin) && (!u.organization || u.organization.id == this.$store.getters.organizationId)))
    },
    availableAdmins(){
      let tmp = this.tempSession.admins.map(u => u.id)
      //console.log(tmp)
      return tmp ? this.admins.filter(admin => admin.id && !tmp.includes(admin.id)) : this.admins
    },
    admins(){
      // session admins can be users with roles [Admin,Professor], and if the calling user is "just" a professor, then he can only assign other professors either without an organization, or in the same organization that he is part of
      return this.users.filter(u => u.roles && (u.roles.includes(Roles.Professor) || u.roles.includes(Roles.Admin)) && (this.$store.getters.isAdmin || ((this.$store.getters.isProfessor || this.$store.getters.isOrgAdmin) && (!u.organization || u.organization.id == this.$store.getters.organizationId))) )
    }
  },
  methods: {   
    sessionOwnerSearch(options, term){
      const searchTerm = term.toLowerCase();
      return options.filter(o => o.email.toLowerCase().includes(searchTerm) || o.firstName?.toLowerCase().includes(searchTerm) || o.lastName?.toLowerCase().includes(searchTerm))
    },
    async getContentMasters(){      
        let resp = await axios({ url: "content/master" }) 
        this.experiences = resp.data.filter(x => x.published);      
    },
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data

      // set filters
      let foundIndex = this.columns.findIndex((c) => {
        return c.field == 'organization.name'
      })        
      this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.organizations.map(o => o.name));
    },    
    async getLegacyExperiences(){      
      if(this.$store.getters.isAdmin){        
        let resp = await axios({ url: "experiences" }) 
        this.legacyExperiences = resp.data

        // set filters
        let foundIndex = this.columns.findIndex((c) => {
          return c.field == 'experience.name'
        })        
        this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.legacyExperiences.map(e => e.name));
      }   
    },
    async bulkDelete(){
      if(confirm(`Delete the ${this.selectedSessions.length} selected sessions?`)){
        let forDeletion = this.selectedSessions.map(s => s.id)
        await axios({ url: `sessions`, data: forDeletion, method: 'DELETE' })        
        this.sessions = this.sessions.filter(s => !forDeletion.includes(s.id))
      }
    },
    async deleteRow(id){
      if(confirm(`Delete session with id ${id}?`)){
        await axios({ url: `sessions`, data: [id], method: 'DELETE' })        
        this.sessions = this.sessions.filter(s => s.id != id)
      }
    },
    async editRow(id){     
      if(!this.$route.params.id)
        this.$router.push({name: 'SessionEdit', params: {id: id}}) 
      this.tempSession = JSON.parse(JSON.stringify(this.sessions.find(s => s.id == id))) 
      this.$set(this.tempSession, 'contentBlocks', (await axios.get( `sessions/${id}/contentblocks`)).data )
      this.publishContent = false
      this.showModal = true
    },
    async copyRow(id){      
      this.tempSession = JSON.parse(JSON.stringify(this.sessions.find(s => s.id == id)))       
      this.tempSession.copy = id
      this.tempSession.copyAreas = false
      this.tempSession.id = null
      this.$set(this.tempSession, 'contentBlocks', (await axios.get( `sessions/${id}/contentblocks`)).data )
      this.tempSession.name = "Copy of " + this.tempSession.name
      this.publishContent = false
      this.showModal = true      
    },
    async addRow(){      
      this.tempSession = {
        id: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined,
        structure: undefined,
        ownerId: undefined,
        experienceId: null,
        custom: false,
        contentBlocks: [],
        organizationId: null
      } 
      this.publishContent = false
      this.showModal = true
    },
    async treeItemSelected(item){
      // console.log(item)
      if(item.children == null && (item.type == 'm' || item.type == 'f')){
        let resp = await axios.get(`content/${item.id}/children`)
        item.children = resp.data.filter(x => x.published)
        // console.log("loaded children")
      }
      this.selectedTreeItem = item
    },    
    async contentBlockSelected(block){
      // console.log(block)
      if(block.children == null && (block.type == 'm' || block.type == 'f')){
        let resp = await axios.get(`content/${block.id}/children`)
        block.children = resp.data
        // console.log("loaded children")
      }
      this.selectedContentBlock = block
    },
    addContentBlock(){      
      if(!this.tempSession.contentBlocks.find(b => b.id === this.selectedTreeItem.id)){
        // let tmp = JSON.parse(JSON.stringify(this.selectedTreeItem))
        // delete tmp.children 
        this.tempSession.contentBlocks.push(this.selectedTreeItem)
      }        
    },
    removeContentBlock(){      
      const index = this.tempSession.contentBlocks.findIndex(b => b.id === this.selectedContentBlock.id)
      if(index >= 0){
        this.tempSession.contentBlocks.splice(index, 1)
      }        
    },
    async editUsers(id){      
      if(!this.$route.params.id)
        this.$router.push({name: 'SessionUsers', params: {id: id}}) 
      let resp = await axios({ url: `sessions/${id}/users` }) 
      //console.log("got users of session: " + resp.data)  
      this.tempSession = this.sessions.find(s => s.id == id)
      this.$set(this.tempSession, 'users', resp.data)
      //this.tempSession.users = resp.data
      this.showUsersModal = true
    },
    async editAdmins(id){      
      if(!this.$route.params.id)
        this.$router.push({name: 'SessionAdmins', params: {id: id}}) 
      let resp = await axios({ url: `sessions/${id}/admins` }) 
      //console.log("got admins of session: " + resp.data)  
      this.tempSession = this.sessions.find(s => s.id == id)
      this.$set(this.tempSession, 'admins', resp.data)
      //this.tempSession.admins = resp.data
      this.showAdminsModal = true
    },
    async removeUsers(){          
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.sessionusersdatatable.selectedRows.map(u => u.id)
        await axios({ url: `sessions/${this.tempSession.id}/users`, data: ids, method: 'DELETE' })
        let resp = await axios({ url: `sessions/${this.tempSession.id}/users` })
        this.tempSession.users = resp.data
      }
      catch(err){
        console.log("Error when removing users from session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      } 
    },
    async removeAdmins(){          
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.sessionadminsdatatable.selectedRows.map(u => u.id)
        await axios({ url: `sessions/${this.tempSession.id}/admins`, data: ids, method: 'DELETE' })
        // in case removing self
        if(!this.$store.getters.isAdmin && this.tempSession.ownerId != this.$store.getters.userId && ids.includes(this.$store.getters.userId)){
          this.tempSession = null
          this.getSessions()
          this.showAdminsModal = false
        }
        else{
          let resp = await axios({ url: `sessions/${this.tempSession.id}/admins` })
          this.tempSession.admins = resp.data
        }        
      }
      catch(err){
        console.log("Error when removing admins from session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      } 
    },
    async addUsers(){      
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.usersdatatable.selectedRows.map(u => u.id)
        await axios({ url: `sessions/${this.tempSession.id}/users`, data: ids, method: 'POST' })
        //this.tempSession.users.push(...this.$refs.usersdatatable.selectedRows)
        let resp = await axios({ url: `sessions/${this.tempSession.id}/users` })
        this.tempSession.users = resp.data
      }
      catch(err){
        console.log("Error when adding users to session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }  
    },
    async addAdmins(){      
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.adminsdatatable.selectedRows.map(u => u.id)
        await axios({ url: `sessions/${this.tempSession.id}/admins`, data: ids, method: 'POST' })
        //this.tempSession.admins.push(...this.$refs.adminsdatatable.selectedRows)
        let resp = await axios({ url: `sessions/${this.tempSession.id}/admins` })
        this.tempSession.admins = resp.data
      }
      catch(err){
        console.log("Error when adding admins to session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }  
    },
    structureToClipboard(){
      navigator.clipboard.writeText(this.prepareSessionDto().contentStructure)
      this.$refs.snackbar.show('Session content structure copied to clipboard.')
    },
    prepareSessionDto(updateContent){
      // eslint-disable-next-line
      let {contentBlocks, ...tmpData} = this.tempSession
      tmpData.contentBlockIds = this.tempSession.contentBlocks.map(b => b.id)


      tmpData.contentStructure = updateContent ?
      JSON.stringify({
        type: 'list',
        icon: 'default_session_icon.jpg',
        title: this.tempSession.name,
        txt1: this.tempSession.name,
        bg: 'default_session_bg.jpg',
        list: this.tempSession.contentBlocks.map(b => JSON.parse(b.publishedJson))
      })
      : null

      return tmpData
    },
    async updateSession(){    
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        
        if(this.tempSession.custom || this.tempSession.contentBlocks.length || confirm("Update session without any content?")){
          let resp = await axios({ url: `sessions/${this.tempSession.id}`, data: this.prepareSessionDto(!this.tempSession.custom && this.publishContent), method: "POST" })    
          //console.log(resp.data)      
          let tmp = this.sessions.find(s => s.id == this.tempSession.id)      
          tmp.name = this.tempSession.name
          tmp.startDate = this.tempSession.startDate
          tmp.endDate = this.tempSession.endDate
          tmp.structure = this.tempSession.structure
          tmp.ownerId = resp.data.ownerId
          tmp.experienceId = resp.data.experienceId
          tmp.organizationId = resp.data.organizationId
          tmp.organization = this.organizations.find(o => o.id == resp.data.organizationId)
          tmp.custom = resp.data.custom
          tmp.published = resp.data.published
          this.showModal = false
          this.$router.push({name: 'Sessions'})
        }
      }
      catch(err){
        console.log("Error when updating session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.publishContent = false
        this.loading = false;
        this.$nprogress.done();
      }        
    },
    async addSession(){    
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        
        if(this.tempSession.custom || this.tempSession.contentBlocks.length || this.tempSession.experienceId || confirm("Create a session without any content?")){
          let resp = await axios({ url: `sessions`, data: this.prepareSessionDto(true), method: "POST" })    
          //console.log(resp.data)

          if(this.tempSession.copy && this.tempSession.copyAreas){
            let metadatas = await axios.get(`analytics/metadata?sessionId=${this.tempSession.copy}`)
            for (const metadata of metadatas.data) {
              let url = `analytics/metadata?sessionId=${resp.data.id}&segmentId=${metadata.segmentId}&itemId=${metadata.itemId}`
              await axios.post( url, metadata.areasJson, { headers: { 'Content-Type': 'application/json' } })
            }
          }

          resp.data.organization = this.organizations.find(o => o.id == resp.data.organizationId)
          this.sessions.unshift(resp.data)
          this.showModal = false
        }
      }
      catch(err){
        console.log("Error when adding new session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.publishContent = false
        this.loading = false;
        this.$nprogress.done();
      }        
    },
    formatStatus(status){
      switch(status){
        case 0: return 'NotStarted';
        case 1: return 'Guided';
        case 2: return 'Paused';
        case 3: return 'SelfPaced';
        case 4: return 'Finished';
      }
    },
    async getSessions(){      
        let resp = await axios({ url: "sessions" }) 
        this.sessions = resp.data;      
    },  
    async fetchUsers(){      
        let resp = await axios({ url: "users", method: "GET" }); 
        this.users = resp.data;      
    }
  },  
  components: {
    VueGoodTable, 
    Modal,
    vSelect,
    Sidebar,
    TreeItem,
    Snackbar
  },
  async mounted() {
    try{
      this.$nprogress.start()
      this.loading = true
      this.getLegacyExperiences()
      await this.getContentMasters()
      await this.getSessions()
      await this.getOrganizations()
      await this.fetchUsers() // TODO this will soon need rework so it won't load all users at once
      if(this.$route.params.id){      
        if(this.$route.name == 'SessionEdit')
          await this.editRow(this.$route.params.id)
        else if(this.$route.name == 'SessionUsers')
          await this.editUsers(this.$route.params.id)
        else if(this.$route.name == 'SessionAdmins')
          await this.editAdmins(this.$route.params.id)
      }
    }
    finally{
      this.loading = false
      this.$nprogress.done()
    }
  },
  created() {
  }
}
</script>

<style lang="scss">

.sessionmodal{
  .modal-container{
    width: min-content;
    min-width: 600px;
    max-width: 90%;
  }
  .modal-body > div:first-child{
    display: flex;  
  }

  .basic{
    min-width: 560px;
  }

  .editor{
    display: flex;

    ul.tree{
      margin-top: 0;
    }

    .masters{
      position: relative;
      width: max-content;
      padding-left: 10px;
      margin-left: 10px;
      padding-right: 10px;
      border-right: 1px dashed #a0a0a0;
      border-left: 1px dashed #a0a0a0;
    }

    .details{
      min-width: 180px;
      position: relative;
      padding-left: 10px;
      width: max-content;
    }

    button {
      position: absolute;
      bottom: 0;
      width: calc(100% - 20px);
    }
  }
}

.usersmodal{ 
  .modal-container{
    width: auto;
    max-width: 95%;
    // max-height: 95%;

    .spinner{
      width: 20px;
      height: 20px;
      display: inline-block;
      position: unset;
      margin: 0 20px;
      left: unset;
    }

  }
}

.usertabs{
  display: flex;
  justify-content: space-between;

  .usertab{    
    width: 49%;
    // margin: 0 auto;
    // max-height: 500px;
    // overflow: auto;
  }
}

.rowActions{
  display: flex;
  opacity: 0.0;
}

.vgt-table tr:hover .rowActions{
  opacity: 1;
}

.modal-container{
  width: 600px;
}

.modal-body textarea.jsonarea{
  min-height: 100px;
  resize: vertical;

  &[disabled]{
    opacity: 0.25;
    overflow: hidden;
    resize: none;
  }
}

.modal-footer{
  padding-top: 0;
  button.insead{
    width: 100%;    
  }
}

.v-select{
  clear: both;
}

.form-left{
  width: 47%;
  float: left;
}
.form-right{
  width: 47%;
  float: right;
}

// .newdevice{
//   display: flex;

//   input[type=text]{
//     // max-width: 25%;
//     // float: left;
//     flex-grow: 1;
//     margin-left: 10px;
//   }  

//   button.insead{
//     flex: none;
//   }
// }
</style>
