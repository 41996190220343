<template>
  <li>
    <div
      :class="{folder: isFolder, opened: isOpen, selected: selectedItem == item, newer: newer}"
      :style="{paddingLeft: 40+(level*10)+'px', backgroundPositionX: 25+(level*10)+'px'}"
      :title="newer ? newer : ''"
      @click="toggle">
      {{ editorMode ? (item.type == 'm' ? item.name : (parsedStructure ? parsedStructure.txt1 : '???')) : item.txt1 }}      
      <!-- <span v-if="isFolder"></span> -->
      <!-- {{selectedItem.txt1}} -->
    </div>
    <ul v-show="isOpen" v-if="isFolder" class="tree">
      <tree-item
        class="treeitem"
        v-for="(child, index) in (editorMode ? item.children : item.list)"
        :editorMode="editorMode"
        :publishDate="publishDate"
        @select="selectHandler"
        @opened="openedHandler"
        :key="(editorMode ? (`${level}_${index}_${item.id}`) : `${level}_${index}`)"
        :item="child"
        :selectedItem="selectedItem"
        :parent = item
        :level="level + 1"
      ></tree-item>      
    </ul>
  </li>
</template>



<script>

export default {
  name: 'TreeItem',
  props: {
    item: Object,
    level: Number,
    selectedItem: Object,
    parent: Object,
    editorMode: Boolean,  // true when used for authoring, where we deal with content blocks with embedded structure. false when fed by the json structure directly.
    publishDate: String
  },
  data: function() {
    return {
      isOpen: false,
      //selected: false
    };
  },
  computed: {
    isFolder: function() {
      return this.editorMode
      ? this.item.type == 'm' || this.item.type == 'f'
      : this.item.type == 'list' && this.item.list && this.item.list.length;
    },
    parsedStructure(){
      return this.editorMode ? JSON.parse(this.item.structure) : null
    },
    newer(){
      //if(this.editorMode && (this.item.published > this.publishDate /*|| !this.publishDate*/)){
      if(this.publishDate && this.item.published && (this.item.published > this.publishDate)){
        const itemPublished = new Date(this.item.published)
        const diffTime = Math.abs(new Date() - itemPublished);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        return `Newer version exists since ${itemPublished.toLocaleString()} (${diffDays} days ago)`
      }
      return null
    }
  },
  methods: {
    toggle: function() {
      if (this.isFolder) {
        if(!(this.isOpen && this.item != this.selectedItem) || this.editorMode)
          this.isOpen = !this.isOpen;
      }
      // this.selected = true
      // console.log(this.selected)
      this.$emit("select", this.item)
    },
    selectHandler(event){  
      this.$emit('select', event)
    },    
    openedHandler(){
      this.isOpen = true;
      this.$emit('opened')
    }
    // makeFolder: function() {
    //   if (!this.isFolder) {
    //     this.$emit("make-folder", this.item);
    //     this.isOpen = true;
    //   }
    // }
  },
  watch: {
    // selectedItem: function(newVal, oldVal) { // watch it
    //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    //   }
    selectedItem(newItem) {
      if(/*this.isFolder &&*/ this.item == newItem){
        this.isOpen = true
        this.$emit('opened')
        // console.log(newItem.txt1)
      }
    }
  }
}
</script>



<style lang="scss">
ul.tree{
  list-style-type: none;
  padding: 0;
}

.treeitem{
  min-height: 32px;

  > div {
    padding: 7px 0;
    cursor: pointer;
    line-height: 16px;

    &.selected{
      background-color: #EFF2F4;
    }

    &:hover{
      background-color: #EFF2F4;
      &:active{
        background-color: #E9F4EA;
      }
    }

    &.newer{
      color: $red;
    }
  }

  .folder{
    background-image: url("../assets/triangle.svg");
    background-repeat: no-repeat;
    background-position-y: 10px;
    padding-left: 16px;
    min-height: 32px;

    &.opened{
      background-image: url("../assets/triangle-down.svg");
      background-position-y: 11px;

      &:before {
        background-image: url("../assets/folder-opened.svg");
        background-size: 19px 14px;
      }
    }
    
    &:before {
      content: "";      
      float: left;
      width: 20px;
      height: 14px;
      display: block;
      background-image: url("../assets/folder.svg");
      background-size: 16px 14px;
      background-repeat: no-repeat;
      //background-position-x: 10px;
    }
  }
}
</style>
